// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require('bootstrap/dist/js/bootstrap')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import toastr from 'toastr'
import moment from 'moment';
window.toastr = toastr
window.moment = moment
moment().format();
require("moment/locale/ja")
require("cocoon");

import './stylesheets/application'

$(document).ready(function(){	
  // まとめるカレンダー
  $('.list-members')
    .on('cocoon:after-insert', function() {
      recalculate_priority()
    })
    .on('cocoon:after-remove', function() {
      recalculate_priority()
    })
  function recalculate_priority() {
    $('.nested-fields:visible').each(function(index) {
      $(this).find('input[class=priority-value]').val(index + 1)
      console.log($(this).find('input[class=priority-value]').val())
    });
  }


  // 予約表編集 > 備考欄設定
  $('#question')
    .on('cocoon:before-insert', function(e, question_to_be_added) {
      question_to_be_added.fadeIn('slow');
    })
    .on('cocoon:after-insert', function(e, added_question) {
      const newFormId = added_question.find('textarea').attr('id').split('_')[3] || String(Math.floor(Math.random() * 1000000000));
      const targetRequiredInput = added_question.find('input#checkbox-required-');
      const targetRequiredLabel = added_question.find('label.c-label');
      const targetUseInput = added_question.find('input#checkbox-use-');
      const targetUseLabel = added_question.find('label.u-label');
      targetRequiredInput.attr('id', `checkbox-required-${newFormId}`);
      targetRequiredLabel.attr('for', `checkbox-required-${newFormId}`);
      targetUseInput.attr('id', `checkbox-use-${newFormId}`);
      targetUseLabel.attr('for', `checkbox-use-${newFormId}`);
      var currentHeight = $(".l-frame-body").height()
      $(".l-frame-body").height(currentHeight + 200)
    })
    .on('cocoon:before-remove', function(e, question) {
      question.fadeOut('slow');
      var currentHeight = $(".l-frame-body").height()
      $(".l-frame-body").height(currentHeight - 200)
    });
})
